import { RefObject, useCallback } from 'react';

export const useScrollToAndFocus = (
  inputRef: RefObject<HTMLInputElement> | HTMLInputElement | null,
  containerRef?: RefObject<HTMLElement> | HTMLElement | null,
) => {
  const inputEl = inputRef && 'current' in inputRef ? inputRef.current : inputRef;
  const outerRef = containerRef ? ('current' in containerRef ? containerRef.current : containerRef) : inputEl;

  const trigger = useCallback(() => {
    if (!inputEl || !outerRef) {
      return;
    }

    const observer = new IntersectionObserver(
      (cb: IntersectionObserverEntry[]) => {
        if (cb[0].isIntersecting) {
          observer.unobserve(inputEl);
          setTimeout(() => {
            inputEl.focus();
          }, 100);
        }
      },
      {
        threshold: 1.0,
      },
    );
    observer.observe(inputEl);
    outerRef.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  }, [inputEl, outerRef]);

  return trigger;
};
